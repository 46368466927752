@font-face {
  font-family: AvantGardeDemiBT;
  src: url(./fuentes/AvantGardeDemiBT.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Source+Sans+3:wght@200..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", "Source Sans 3", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(113.88% 50% at 50% 50%, #0081e3 0%, #002039 100%);
  background-attachment: fixed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"] {
  @apply hidden;
}

input[type="checkbox"] {
  @apply hidden;
}

:root {
  --trackColor: rgb(96, 96, 96);
  --thumbColor: white;
}

.slider-thumb-appearance {
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  width: 80%;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(
    90deg,
    rgba(122, 193, 71, 0) 0%,
    var(--trackColor) 100%
  );
  height: 12px;
  border-radius: 25px;
}

input[type="range"]::-moz-range-track {
  background: linear-gradient(
    90deg,
    rgba(122, 193, 71, 0) 0%,
    var(--trackColor) 100%
  );
  height: 12px;
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -7.5px;
  background-color: var(--thumbColor);
  height: 23px;
  width: 23px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  border-radius: 0;
  background-color: var(--thumbColor);
  height: 23px;
  width: 23px;
  border-radius: 50%;
}
/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid var(--thumbColor);
  outline: 3px solid var(--thumbColor);
  outline-offset: 0.125rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid var(--thumbColor);
  outline: 3px solid var(--thumbColor);
  outline-offset: 0.1rem;
}
#root {
  white-space: pre-line;
}
