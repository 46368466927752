.placeholder::placeholder {
  color: white;
}

.atenea {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 1s linear;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.flip {
  transform: rotateY(360deg);
}
