:root {
  --textColor: #000000;
}

.img-logo {
  @media (max-width: 600px) {
    width: 80vw;
  }

  @media (min-width: 601px) {
    width: 400px;
  }
}

.txt-label {
  color: var(--textColor);
  font-weight: 500;
  font-size: 20px;
}

.txt-validation {
  color: #fa7373;
  font-weight: 700;
  font-size: 14px;
  // mix-blend-mode: difference;
  // text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  //   1px 1px 0 #000;
}

.txt-label-title {
  color: white;
  font-weight: 600;
  font-size: 26px;
}

.txt-welcomeMessage {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-welcome {
  color: white;
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
  }
}

.checkbox-style {
  color: var(--textColor) !important;
  font-family: "OpenSans-Regular" !important;
  font-weight: bold !important;
  align-self: center !important;
}

.form-style {
  color: var(--textColor) !important;
  font-family: "OpenSans-Regular" !important;
  font-weight: bold !important;
  margin-left: 10vw !important;
  margin-right: 10vw !important;
}

.select-stuff {
  border-color: var(--textColor) !important;
  color: var(--textColor);
  fill: var(--textColor);
}

MuiSelect-select {
  border-color: var(--textColor);
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: var(--textColor);
  color: var(--textColor);
}

.datePicker {
  // color: white;
  input {
    color: var(--textColor);
    width: 100%;
    border-radius: 10px;
    border-color: var(--textColor);
    border-style: solid;
    border-width: 1px;
    background-color: transparent;
    margin-left: 0;
    height: 40px;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}

.muiinputstuff {
  input {
    color: var(--textColor);
    border-color: var(--textColor) !important;
  }
  MuiInputBase-root {
    color: var(--textColor);
  }
  color: var(--textColor);
}

.img-flecha-inv {
  transform: rotate(180deg);
}

.inverted {
  filter: var(--inverted);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}